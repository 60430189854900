import React from 'react'
import {
    Table,
    TableHead,
    HeaderCell,
    TableRow,
    DataCell,
    TableBody,
} from '@entur/table'
import DownloadLink from '~/components/DownloadLink/DownloadLink'

type DataSetsProps = {
    importDates: Record<string, string>
}

const names: Record<string, string> = {
    ost: 'Østfold (Østfold kollektivtrafikk) **',
    rut: 'Ruter **',
    inn: 'Innlandet (Innlandstrafikk)',
    bra: 'Buskerud (Brakar) **',
    vkt: 'Vestfold (VKT) **',
    tel: 'Telemark (Farte) **',
    akt: 'Agder (AKT)',
    kol: 'Rogaland (Kolumbus)',
    sky: 'Hordaland (Skyss) **',
    sof: 'Sogn og Fjordane (Kringom) **',
    mor: 'Møre og Romsdal (Fram)',
    atb: 'Trøndelag (AtB)',
    nor: 'Nordland (Nordland fylkeskommune)',
    tro: 'Troms (Troms fylkestrafikk) **',
    fin: 'Finnmark (Snelandia) **',
    goa: 'Go-Ahead Nordic',
    sjn: 'SJ Nord',
    vyg: 'Vy Rail',
    vyx: 'Vy Bus Norway',
    vyb: 'Vy Bus Sweden',
    flt: 'Flytoget',
    bef: 'Beffen',
    bor: 'Boreal',
    nyc: 'Bygdøyfergen (Norway Yacht Charter)',
    gof: 'Go Fjords',
    hav: 'Havila',
    hur: 'Hurtigruten',
    ftr: 'Flåm Travel',
    nwy: 'NOR-WAY Bussekspress',
    nbu: 'Flybussen Connect',
    tid: 'Tide Buss',
    tts: 'Torghatten',
    osc: 'Oscarsborgfergen (Forsvarsbygg)',
    uni: 'Unibuss',
    vog: 'Voss Gondol',
    atu: 'Ålesund Turvogn Service',
    avi: 'Domestic flights (Avinor)',
    ulr: 'Ulriken',
    vil: 'Visit Lillehammer',
    haf: 'Hafjell Alpinsenter',
    fli: 'Flixbus',
    glo: 'GlobeOrbit100',
    gfs: 'Geiranger Fjordservice',
    vip: 'Oslo VIP Transporttjenester',
    hog: 'Høgsfjordferja',
    bsr: 'Bussring',
    ois: 'FM Øisang',
    tfj: 'The Fjords',
}

const DataSets: React.FC<DataSetsProps> = ({ importDates }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <HeaderCell>Name</HeaderCell>
                    <HeaderCell>NeTEx</HeaderCell>
                    <HeaderCell>GTFS</HeaderCell>
                    <HeaderCell>GTFS Basic</HeaderCell>
                    <HeaderCell>Imported</HeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <DataCell>Entire Norway</DataCell>
                    <DataCell>
                        <DownloadLink href="https://storage.googleapis.com/marduk-production/outbound/netex/rb_norway-aggregated-netex.zip" />
                    </DataCell>
                    <DataCell>
                        <DownloadLink href="https://storage.googleapis.com/marduk-production/outbound/gtfs/rb_norway-aggregated-gtfs.zip" />
                    </DataCell>
                    <DataCell>
                        <DownloadLink href="https://storage.googleapis.com/marduk-production/outbound/gtfs/rb_norway-aggregated-gtfs-basic.zip" />
                    </DataCell>
                </TableRow>
                {Object.keys(names).map((key: string) => (
                    <TableRow>
                        <DataCell>{names[key]}</DataCell>
                        <DataCell>
                            <DownloadLink
                                href={`https://storage.googleapis.com/marduk-production/outbound/netex/rb_${key}-aggregated-netex.zip`}
                            />
                        </DataCell>
                        <DataCell>
                            <DownloadLink
                                href={`https://storage.googleapis.com/marduk-production/outbound/gtfs/rb_${key}-aggregated-gtfs.zip`}
                            />
                        </DataCell>
                        <DataCell>{}</DataCell>
                        <DataCell>
                            {importDates[key] &&
                                new Date(importDates[key]).toLocaleString()}
                        </DataCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default DataSets
