import React from 'react'

import {
    filterChanges,
    parseChanges,
    sortChangesByDate,
    ChangelogProps,
} from '~/components/Changes/Changelog'
import { ChangelogEntry } from '~/components/Changes/ChangelogEntry'

import {
    ChangeTypeFilters,
    TypeFilterStates,
} from '~/components/Changes/ChangeTypeFilters'

import { ExpandableTextButton } from '@entur/expand'

// Partner Services:
import cerseiChangelogData from '@pages/cersei/docs/changelog-cersei.json5'
import receiptChangelogData from '@pages/receipt/docs/changelog-receipt.json5'
import refundChangelogData from '@pages/refund/docs/changelog-refund.json5'
import orderChangelogData from '@pages/order/docs/changelog-order.json5'
import paymentChangelogData from '@pages/payment/docs/changelog-payment.json5'
import ticketDistributionChangelogData from '@pages/ticket-distribution/docs/changelog-ticket-distribution.json5'
import seatingArrangementChangelogData from '@pages/seating-arrangement/docs/changelog-seating-arrangement.json5'

import benefitsChangelog from '@pages/benefits/docs/changelog-benefits.json5'
import consentsChangelog from '@pages/consents/docs/changelog-consents.json5'
import profilesChangelog from '@pages/profiles/docs/changelog-profiles.json5'
import customersOrchestratorChangelog from '@pages/customers/docs/changelog-customers-orchestrator.json5'

import personnelTicketsChangelog from '@pages/personalbillett/docs/changelog-personnel-tickets.json5'

import personalisationClientChangelog from '@pages/personalisation/docs/changelog-personalisation-client.json5'

import productsChangelog from '@pages/products/docs/changelog-products.json5'
import offersChangelog from '@pages/offers/docs/changelog-offers.json5'

import inventoryChangelog from '@pages/inventory/docs/changelog-inventory.json5'

// Internal

import rollingStockChangelog from '@pages/rolling-stock/docs/developer-portal/developer-portal/changelog-rolling-stock.json5'

import electronicJournalChangelog from '@pages/electronic-journal/docs/changelog-electronic-journal.json5'
import posRegisterChangelog from '@pages/pos-registry/docs/changelog-pos-register.json5'
import travelCardChangelog from '@pages/travel-card/docs/changelog-travel-card.json5'

import organisationsChangelog from '@pages/organisations/docs/changelog-organisations.json5'
import agreeementsChangelog from '@pages/agreements/docs/changelog-agreements.json5'
import distributionChannelsChangelog from '@pages/distribution-channels/docs/changelog-distribution-channels.json5'

import './AggregatedChanges.scss'
import { getChangelogFromGithubReleases } from '~/utils/releases'

const staticData = [
    cerseiChangelogData,
    receiptChangelogData,
    refundChangelogData,
    orderChangelogData,
    paymentChangelogData,
    ticketDistributionChangelogData,
    seatingArrangementChangelogData,
    benefitsChangelog,
    consentsChangelog,
    profilesChangelog,
    customersOrchestratorChangelog,
    personnelTicketsChangelog,
    personalisationClientChangelog,
    productsChangelog,
    offersChangelog,
    inventoryChangelog,
    rollingStockChangelog,
    electronicJournalChangelog,
    posRegisterChangelog,
    travelCardChangelog,
    organisationsChangelog,
    agreeementsChangelog,
    distributionChannelsChangelog,
]

export default function AggregatedChanges(): JSX.Element {
    const [changelogData, setChangelogData] = React.useState(staticData)

    React.useEffect(() => {
        getChangelogFromGithubReleases({
            owner: 'entur',
            repo: 'sdk',
            repoName: 'SDK',
        }).then((sdkChangelog) =>
            setChangelogData((prev) => [...prev, sdkChangelog]),
        )
    }, [])

    return <AggregatedChangesContent changelogData={changelogData} />
}

const aggregatedChangesFilterStates: TypeFilterStates = {
    breaking: true,
    deprecated: true,
    improvement: true,
    new: true,
    bugfix: true,
}

const AggregatedChangesContent: React.FC<ChangelogProps> = ({
    changelogData,
}) => {
    const [typeFilter, setTypeFilter] = React.useState(
        aggregatedChangesFilterStates,
    )
    const [showAllChanges, setShowAllChanges] = React.useState(false)

    const parsedChanges = parseChanges(changelogData)
    const filteredChanges = filterChanges(parsedChanges, typeFilter)
    const sortedChanges = sortChangesByDate(filteredChanges)

    return (
        <div className="aggregated-changes">
            <ChangeTypeFilters
                typeFilter={typeFilter}
                setTypeFilter={setTypeFilter}
                filtersToShow={{
                    breaking: true,
                    deprecated: true,
                    improvement: true,
                    new: true,
                    bugfix: true,
                }}
            />
            {sortedChanges.slice(0, 3).map((change, index) => (
                <ChangelogEntry key={index} change={change} />
            ))}
            {showAllChanges &&
                sortedChanges
                    .slice(3)
                    .map((change, index) => (
                        <ChangelogEntry key={index} change={change} />
                    ))}

            {sortedChanges.length > 3 ? (
                <div className="aggregated-changes__see-more">
                    <ExpandableTextButton
                        open={showAllChanges}
                        onToggle={() => setShowAllChanges((prev) => !prev)}
                    >
                        {showAllChanges ? 'See less' : 'See more'}
                    </ExpandableTextButton>
                </div>
            ) : (
                <div className="aggregated-changes__see-more--hidden" />
            )}
        </div>
    )
}
